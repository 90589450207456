@font-face {
  font-family: AdobeCaslonPro;
  src: local(ACaslonPro-Regular),
    url(/fonts/ACaslonPro/ACaslonPro-Regular.otf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: NovecentoSlab;
  src: local(Novecento_Slab_DemiBold),
    url(/fonts/Novecento_Slab/Novecento_Slab_DemiBold.otf) format("truetype");
  font-weight: 650;
  font-style: normal;
}

@font-face {
  font-family: Inter;
  src: local(Inter-Medium),
    url(/fonts/Inter/Inter-Medium.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: HelveticaNeueLTStd;
  src: local(HelveticaNeueLTStd-BdCn),
    url(/fonts/HelveticaNeueLTStd/HelveticaNeueLTStd-BdCn.otf) format("truetype");
  font-weight: bold;
  font-style: normal;
}