@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

.atom__typography-container {
  position: relative;
  margin: 0;

  font-family: AdobeCaslonPro;
  font-size: 1.6rem;
  line-height: 3rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: $darkgray;

  & > p {
    margin: 0;

    &:not(:last-child) {
      padding: 0 0 2rem;
    }
  }

  &[data-bold] {
    font-weight: bold;
  }

  &[data-color="white"] {
    color: $white;
  }

  &[data-color="dark"] {
    color: $darkgray;
  }

  &[data-color="lightgray"] {
    color: $lightgray;
  }
  
  &[data-type="inter"] {
    font-family: Inter;
    font-size: 1.6rem;
    line-height: 2.4rem;
    letter-spacing: -0.04em;
    color: $darkgray;
  }

  &[data-type="helvetica"] {
    font-family: HelveticaNeueLTStd;
    font-size: 1.4rem;
    line-height: 1.7rem;
    display: flex;
    align-items: center;
  }

  @include gt-phone {
    font-size: 2.4rem;
    line-height: 4.2rem;
    
    &[data-type="helvetica"] {
      font-size: 1.6rem;
      line-height: 1.9rem;
    }
  }
}
