*, *::before, *::after {
  box-sizing: border-box;
}

html, body {
  height: 100%;
  font-size: 10px;
  margin: 0;
  padding: 0;
  background-color: #fff;
  
  font-family: ToyotaType, Arial, Helvetica, sans-serif;
  font-smoothing: antialiased;
  webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-display: swap;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
}
/* 
#__next {
  svg {
    fill: currentColor;
  
    path {
      fill: currentColor;
    }
  }  
} */

h1 {
  font-size: 2.125rem;
  line-height: 40px;
  text-transform: uppercase;
}

h2 {
  font-size: 1.5rem;
  line-height: 28px;
  font-weight: 600;
  text-transform: uppercase;
}

h3 {
  font-size: 1.5rem;
  line-height: 28px;
  font-weight: normal;
}

h4 {
  font-size: 1.125rem;
  line-height: 28px;
  font-weight: normal;
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 0.3s;
}

.fade-exit {
  display: none;
}

@import 'utils/styles/_colors';
@import 'utils/styles/grid-system.scss';

/******************************************
 * Components global scope rules
 ******************************************/
body {
  overflow-x: hidden;
  
  &[data-menu-open="1"] {
    overflow: hidden;
  }
}

/*
 * CONTENT
 */
 [data-content-wrapper],
 .content-wrapper {
   position: relative;
   width: 100%;
   padding: 0.5rem;
  //  font-size: 1.4rem;

  @include gt-phone {
    max-width: 80rem;
    margin: auto;
    padding: 2rem;
  }
 
  @include gt-tablet {
    max-width: 144rem;
    margin: auto;
    padding: 4rem;
  } 
 }
 
 [data-content-wrapper="small"] {
   max-width: 71rem;
 }

button {
  cursor: pointer;
  background: transparent;
  border: 0;
  padding: 0;
}

figure {
  margin: 0;
}

br {
  margin: 1rem 0;
}

.modal-open {
  height: 100vh;
  overflow-y: hidden;
}